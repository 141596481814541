import type { ColorThemeEntry } from '../types'
import { TypographyScale, floatToMinimalSting, wrapFontFamily } from '../utils/typography'

export const baseWidth = 860
export const maxWidth = 920
export const baseFontSize = 24
export const baseLineHeight = 1.5
export const headerWeight = 400
export const bodyWeight = 200
export const boldWeight = 600
export const scaleRatio = 2.3
export const multiplicity = 4
export const headerFonts = ['Ruslan Display']
export const bodyFonts = ['Fira Sans Condensed']
export const animationDuration = '.2s'
export const borderRadius = 3
export const defaultPadding = .5

export const bodyFamily = bodyFonts.map(wrapFontFamily).join(',')
export const headerFamily = headerFonts.map(wrapFontFamily).join(',')

export const ts = new TypographyScale({
  baseFontSize: baseFontSize,
  baseLineHeight: baseLineHeight,
  scaleRatio: scaleRatio,
  multiplicity: multiplicity
})

export const getLinesHeightRel = ts.getLinesHeightRel.bind(ts)
export const getLinesHeightAbs = ts.getLinesHeightAbs.bind(ts)
export const scaleRel = ts.scaleRel.bind(ts)
export const scaleAbs = ts.scaleAbs.bind(ts)
export const lineHeightForFontRel = ts.lineHeightForFontRel.bind(ts)
export const lineHeightForFontAbs = ts.lineHeightForFontAbs.bind(ts)

export const em = (n: number) => floatToMinimalSting(n) + 'em'
export const rem = (n: number) => floatToMinimalSting(n) + 'rem'
export const px = (n: number) => n + 'px'

export const linesEm = (n: number) => em(getLinesHeightRel(n))
export const linesRem = (n: number) => rem(getLinesHeightRel(n))
export const linesPix = (n: number) => px(getLinesHeightAbs(n))

export const h1Size = scaleRel(7 / 5)
export const h2Size = scaleRel(3 / 5)
export const h3Size = scaleRel(2 / 5)
export const h4Size = scaleRel(0 / 5)
export const h5Size = scaleRel(-1 / 5)
export const h6Size = scaleRel(-1.5 / 5)

export const minmax = (value: number, [min, max]: [number, number]) =>
  Math.min(Math.max(value, min), max)

const primaryColorLight = '#AC6E31'
const primaryColorDark = '#FFE090'

const linkColorDark = ''
const hoverColorDark = ''
const hoverActiveDark = ''

const shadowColorDark = ''

export const COLOR_THEMES: ColorThemeEntry[] = [
  {
    key: 'light',
    variables: {
      background: 'white',
      textColor: 'black',
      primaryColor: '#AC6E31'
    }
  },
  {
    key: 'dark',
    variables: {
      background: 'black',
      textColor: 'white',
      primaryColor: '#FFE090'
    }
  }
]

export const COLOR_THEMES_KEYS = COLOR_THEMES.map(t => t.key)
export const COLOR_THEMES_COUNT = COLOR_THEMES_KEYS.length