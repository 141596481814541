import React from 'react'
// import { PrimeReactProvider } from 'primereact/api'
import './styles/global.css'
import './styles/default.css'
//import 'primereact/resources/themes/bootstrap4-light-blue/theme.css'
import 'primeicons/primeicons.css'
import BaseVariables from './styles/base-variables'

const AppRoot: React.FC<{ element: React.ReactNode }> = ({ element }) => {
  return <>
    <BaseVariables></BaseVariables>
    {element}
    {/* <PrimeReactProvider value={{ unstyled: false }}>{element}</PrimeReactProvider> */}
  </>
}

export default AppRoot
