import { PageProps } from 'gatsby'
import React from 'react'

const PageRoot: React.FC<{ element: React.ReactNode; props: PageProps }> = ({
  element,
  props
}) => {
  return <>{element}</>
}

export default PageRoot
