import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { ColorThemeEntry } from '~/types'
import { COLOR_THEMES, baseFontSize, baseLineHeight, bodyFamily, bodyWeight, boldWeight, borderRadius, defaultPadding, em, h1Size, h2Size, h3Size, h4Size, h5Size, h6Size, headerFamily, headerWeight, linesEm, linesRem, multiplicity, px, rem, scaleRatio, scaleRel } from './variables'

const ROOT_STYLES = {
  bodyFamily,
  headerFamily,
  bodyWeight,
  boldWeight,
  headerWeight,
  bodySize: px(baseFontSize),
  lineHeight: baseLineHeight,
  borderRadius: px(borderRadius),
  padding: rem(defaultPadding),
  h1Size: em(h1Size),
  h2Size: em(h2Size),
  h3Size: em(h3Size),
  h4Size: em(h4Size),
  h5Size: em(h5Size),
  h6Size: em(h6Size),
  lines25: linesEm(.25),
  lines50: linesEm(.5),
  lines75: linesEm(.75),
  lines100: linesEm(1),
  lines150: linesEm(1.5),
  lines200: linesEm(2),
  lines250: linesEm(2.5),
  lines300: linesEm(3),
}

function buildRootStyles(rootStyles: object) {
  const lines: string[] = [`:root {`]
  for (const [key, value] of Object.entries(rootStyles)) {
    lines.push(`  --${key}: ${value};`)
  }
  lines.push(`}`)
  return lines.join('\n')
}

function buildColorTheme(colorThemes: ColorThemeEntry[]) {
  return colorThemes.map(({ key, variables }) => {
    const lines: string[] = [`:root.${key} {`]
    for (const [key, value] of Object.entries(variables)) {
      lines.push(`  --${key}: ${value};`)
    }
    lines.push(`}`)
    return lines.join('\n')
  }).join('\n\n')
}

const css = [buildRootStyles(ROOT_STYLES), buildColorTheme(COLOR_THEMES)].join('\n\n')

export default createGlobalStyle`${css}`